<template>
  <div class="ml-5 mr-5">
    <div class="text-right my-4">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
 </div>
    <div class="text-right mb-3">
      <template>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small class="mx-2" color="primary" dark v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('add')">
              Add
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add Audience</span>
            </v-card-title>
            <v-form
              ref="form"
              @submit="addAudienceData"
              method="post"
              v-model="valid"
              lazy-validation
            >
              <v-container class="px-50 v-card-padding" fluid>
                <v-text-field
                  v-model="addAudience.audienceName"
                  label="Audience Name"
                  :rules="nameRules"
                  required
                ></v-text-field>
                <div style="color: red">
                  {{ message1 }}
                </div>
              </v-container>
              <v-container class="px-50" fluid> </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" type="submit" @click="validate">
                  Submit
                </v-btn>
                <v-btn class="close_button mx-2" text @click="reset"> Close </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>
      <v-dialog max-width="600" v-model="dialog2">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small color="error" v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('delete')">Delete</v-btn>
        </template>
        <div v-if="deleteItems.length > 0">
          <template>
            <v-card>
              <v-card-text>
                <div class="text-h3 pa-12">
                  Are you sure you want to delete all the selected Record(s)?
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-form ref="form" method="post">
                  <v-btn
                    text
                    class="confirm_button mx-2"
                    v-on:click="dialog2 = false"
                    @click="deleteAudience"
                    >Yes</v-btn
                  >
                </v-form>
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
        <div v-if="deleteItems.length == 0">
          <template>
            <v-card>
              <v-card-text class="center">
                <div class="text-h3 pa-12">
                  Please Select Record(s)? to delete
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
      </v-dialog>
    </div>
    <!-- v-data-table  -->
    <v-row>
      <v-col cols="12">
          <div class="tableWrapper">
            <div id="select-all-checkbox">
              <v-checkbox
                ref="n"
                v-model="allSelected"
                @click="selectAll"
                v-show="checkIfOperationExistForModule('delete')"
              ></v-checkbox>
            </div>
            <v-data-table
              :headers="headers"
              item-key="product_id"
              :items="audience_data"
              :options.sync="options"
              :server-items-length="total"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
            >
              <template v-slot:item.selectCheckbox="{ item }">
                <v-checkbox
                  ref="n"
                  v-model="deleteItems"
                  :value="item.audience_id"
                  :key="item.audience_id"
                  @click="addToDelete($event, item.audience_id)"
                  v-show="checkIfOperationExistForModule('delete')"
                ></v-checkbox>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                      medium
                      color="grey"
                      @click="addToUpdate($event, item.audience_id)"
                      v-show="checkIfOperationExistForModule('edit')"
                    >
                      mdi-pencil
                    </v-icon>
              </template>
            </v-data-table>
          </div>
      </v-col>
    </v-row>
    <v-row justify="center">
     <v-dialog
      v-model="toggleUpdateModal"
      max-width="600"
    >
      <template>
	  <v-card>
                    <v-card-title>
                      <span class="headline">Update Audience</span>
                    </v-card-title>
                    <v-form
                      ref="form"
                      @submit="updateAudienceData"
                      method="put"
                    >
                      <v-container class="px-50" fluid>
                        <v-text-field
                          v-model="updateAudience.audienceName"
                          label="Audience Name"
                          required
                          :rules="nameRules"
                        ></v-text-field>
                        <div style="color: red">
                          {{ upmessage1 }}
                        </div>
                      </v-container>
                      <v-container class="px-50" fluid> </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          @click="updateAudienceData"
                          type="submit"
                        >
                          Update
                        </v-btn>
                        <v-btn class="close_button mx-2" text @click="toggleUpdateModal = false">
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
	  </template>
     </v-dialog>
</v-row>
    <!-- v-data-table-ends -->
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { Index, Buttons, Loading },
  data() {
    return {
      toggleUpdateModal:false,
      //////table data starts here////
      selected: [],
      options: {},
      sort: "",
      total: 0,
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      // //  minDate: "2020-01-05",
      // //  maxDate: "2019-08-30",
      headers: [
        {
          text: "",
          value: "selectCheckbox",
          width: "50px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Audience Name",
          value: "audience_name",
          width: "200px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Action",
          value: "actions",
          width: "150px",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
      valid: true,
      checkbox: false,
      isLoading: true,
      fullPage: true,
      audienceName: [],
      audience_data: [],
      dialog: false,
      dialog1: false,
      dialog2: false,
      deleteItems: [],
      checked_audienceId: [],
      checkcedstr: "",
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      allSelected: false,
      addAudience: {
        audienceName: null,
      },
      updateAudience: {
        audienceId: null,
        audienceName: null,
      },
      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
      addAudiencelabel: null,
    };
  },

  methods: {
    checkIfOperationExistForModule(type){
        return this.$utils.checkIfOperationExistForModule(this, type)
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.dialog = false;
      this.dialog1 = false;
      this.message1 = "";
      this.upmessage1 = "";
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    getAudience() {
      let check = this;
      const path = this.$url("AUDIENCE");
      check.isLoading = true;
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check })
        .get(path)
        .then((res) => {
          check.isLoading = false;
          this.audienceName = res.data.payload.audienceId;
          this.total = this.audienceName.length;
          if (Array.isArray(this.audienceName) && this.audienceName.length) {
            this.audience_data = [];
            let i;
            for (i = 0; i < this.audienceName.length; i++) {
              let tempobj = {
                audience_id: this.audienceName[i][0],
                audience_name: this.audienceName[i][1],
              };
              this.audience_data.push(tempobj);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addAudienceData(e) {
      e.preventDefault();
      let view = this;
      view.isLoading = true
      console.log(this.addAudience.audienceName);
      if (
        this.addAudience.audienceName != "" &&
        this.addAudience.audienceName != null
      ) {
        this.$fetch({ requiresAuth: true, operation : 'add' , vueScope: view })
          .post(this.$url("AUDIENCE"), view.addAudience)
          .then((result) => {
            view.isLoading = false
            console.log(result.data.payload);
            view.message1 = result.data.payload[0].audienceId;
            view.message2 = result.data.payload[0].audienceName;
            if (view.message2 == null) {
              view.message1 = result.data.payload[0].audienceId;
              view.dialog = true;
            } else {
              view.message1 = null;
              view.dialog = false;
              if (result.data.statusCode == 200 && this.message1 == null) {
                view.$swal.fire({
                  icon: "success",
                  title: "Record(s) added successfully",
                  text: result.data.payload.message,
                });
              }
              view.getAudience();
              view.reset();
            }
            console.log(result);
          }).catch((error) => {
           console.error(error);
           view.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
      }
    },
    addToUpdate(e, atchid) {
      let view = this;
      this.toggleUpdateModal = true
      view.audienceName.forEach(function (o, i) {
        if (o[0] == atchid) {
          view.updateAudience.audienceName = o[1];
          view.updateAudience.audienceId = o[0];
        }
      });
    },
    updateAudienceData(e) {

      e.preventDefault();
      let data = this;
      data.isLoading = true
      if (
        data.updateAudience.audienceName != "" &&
        data.updateAudience.audienceName != null
      ) {
        this.$fetch({ requiresAuth: true, operation: 'edit', vueScope: data })
          .put(data.$url("AUDIENCE"), data.updateAudience)
          .then((result) => {
            data.isLoading = false
            this.upmessage1 = result.data.payload.audienceId;
            this.upmessage2 = result.data.payload.audienceName;
            if (this.upmessage2 == null) {
              this.upmessage1 = result.data.payload.audienceId;
              this.dialog1 = true;
            } else {
              this.upmessage1 = null;
              this.dialog1 = false;
              if (result.data.statusCode == 200 && this.upmessage1 == null) {
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) updated successfully",
                  text: result.data.payload.message,
                });
                this.toggleUpdateModal = false
              }
              this.getAudience();
              this.reset();
            }

            console.log(result);
          }).catch((error) => {
           console.error(error);
           data.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
      }
    },
    addToDelete(e, atchid) {
      this.checkedthis = false;
      if (e.target.checked) {
        if (this.checked_audienceId.indexOf(e.target.value) == -1) {
          this.checked_audienceId.push(e.target.value);
        }
      } else {
        this.checked_audienceId.splice(
          this.checked_audienceId.indexOf(e.target.value),
          1
        );
      }
      let checkcedstr = this.checked_audienceId.join(",");
      this.checkcedstr = checkcedstr;
      console.log(this.checkcedstr);
    },
    deleteAudience(e) {
      e.preventDefault();
      let view = this;
      view.isLoading = true
      console.log(this.deleteItems);
      this.$fetch({ requiresAuth: true, operation : 'delete', vueScope: view })
        .delete(this.$url("AUDIENCE"), {
          params: { id: this.deleteItems },
        })
        .then((response) => {
          view.isLoading = false
          if (response.data.statusCode == 200) {
            this.$swal.fire({
              icon: "success",
              title: "Selected record(s) has been deleted",
              text: response.data.payload.message,
            });
          }
          view.getAudience();
          view.deleteItems = [];
        }).catch((error) => {
           console.error(error);
           view.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
    },
    selectAll: function () {
      let audiences = this.audienceName;
      if (this.allSelected == true) {
        if (Array.isArray(audiences) && audiences.length) {
          this.deleteItems = [];
          let i;
          for (i = 0; i < audiences.length; i++) {
            console.log(audiences[i]);
            this.deleteItems.push(audiences[i][0]);
          }
        }
      } else if (this.allSelected == false) {
        this.deleteItems = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
  },
  watch: {
 '$store.state.Rawdata.current_active_module': function(o) {
 if(o){
 this.getAudience(1);
 }
 },
 },
};
</script>
